export function useGetS3ContentQueryKey(s3Key: string, isUserContent: boolean) {
  return [isUserContent ? "getUserContent" : "getCloudContent", s3Key];
}

export function getTasksDataQueryKey() {
  return ["getTasksData"];
}
export function fetchPublicTasksQueryKey() {
  return ["fetchPublicTasks"];
}

export function fetchModelTaskIDFromModelIDQueryKey(modelID: string) {
  return ["fetchModelTaskIDFromModelIDQueryKey", modelID];
}
export function fetchModelPresetByIDQueryKey(presetID: string) {
  return ["fetchModelPresetByIDQueryKey", presetID];
}
export function fetchModelPresetByUserIDQueryKey(userID: string) {
  return ["fetchModelPresetByUserID", userID];
}

export function fetchCurrentUserQueryKey() {
  return ["fetchCurrentUser"];
}
export function fetchCurrentUserTaskResultsQueryKey(userID: string) {
  return ["fetchCurrentUserTaskResults", userID];
}
export function fetchTaskResultsByIDQueryKey(taskID: string) {
  return ["fetchTaskResultsByIDQueryKey", taskID];
}

export function fetchUserByIDQueryKey(userID: string) {
  return ["fetchUserByID", userID];
}
export function fetchUserByUsernameQueryKey(username: string) {
  return ["fetchUserByUsernameQueryKey", username];
}

export function fetchCurrentUserSocialManPostByIDQueryKey(postID: string) {
  return ["fetchCurrentUserSocialManPostByID", postID];
}

export function fetchCurrentUserSocialManPostsQueryKey(userID: string) {
  return ["fetchCurrentUserSocialManPosts", userID];
}

export function fetchModelPresetByModelIDQueryKey(modelID: string) {
  return ["fetchModelPresetByModelID", modelID];
}
export function listUserComfyTokensQueryKey() {
  return ["listUserComfyTokens"];
}
